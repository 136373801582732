import moment, { Moment } from 'moment';
import addMinutes from 'date-fns/addMinutes';
import parseISO from 'date-fns/parseISO';
//import LocaleUtilities from './LocaleUtilities';

export default class DateUtilities {
  public static showTime(timeString: string) {
    if (timeString && !timeString.startsWith('00')) return true;
    return false;
  }

  public static toUTC = (date: Date | null | undefined): Date | null => {
    return date ? addMinutes(date, -date.getTimezoneOffset()) : null;
  };

  public static parseISOWithoutTimeZone = (date: string): Date | null => {
    return DateUtilities.toUTC(parseISO(date));
  };

  public static toISOStringWithoutTimeAndTz = (date: Date): string => {
    date.setUTCHours(0,0,0,0);
    return date.toISOString();
  };

  public static filterStringWithDelta = (daysDelta: number): string => {
    return DateUtilities.toISOStringWithoutTimeAndTz(DateUtilities.getTodayWithDayDelta(daysDelta))
  };

  public static toLocal = (date: Date | null): Date | null => {
    return date ? addMinutes(date, date.getTimezoneOffset()) : null;
  };

  public static todayWithOutTimeZone = (): Date => {
    const date = moment().format('YYYY-MM-DD') + ' 00:00:01'.toString();
    return DateUtilities.parseISOWithoutTimeZone(date) ?? new Date();
  };

  public static dateWithoutTime(dateTime: Date | null): Date | null {
    dateTime?.setHours(0,0,0,0);
    return dateTime;
  }

  public static getDateWithDayDelta(date: Date, daysDelta: number): Date {
    date.setDate(date.getDate() + daysDelta);
    return date;
  }

  public static getTodayWithDayDelta(daysDelta: number): Date {
    const dateTime = new Date();
    return DateUtilities.getDateWithDayDelta(dateTime, daysDelta);
  }

  public static getDateTimeDiffInSec(dateStart: Date | null, dateEnd: Date | null): number | null {
    if (dateStart == null || dateEnd == null) return null;
    var start = new Date(dateStart),
    end = new Date(dateEnd),
    diff = Math.abs(Math.round((end.getTime() - start.getTime())/1000)); 
    return diff;
  }    

  public static getSecSinceMidnight(dateTime: Date): number | null {
    var midnight = this.dateWithoutTime(new Date(dateTime));
    return (moment(dateTime).unix() - moment(midnight).unix()); 
  }    

  public static dateIsInPast(date: Date | Moment) {
    return new Date(date.toString()) < new Date();
  }

  public static dateIsToday(date: Date | Moment | undefined): boolean {
    if (date === undefined) return false;
    return moment().isSame(date, 'day');
  }

  public static isoWeekDay = (date?: Date | Moment): number | undefined => {
    if (date) return moment(date).isoWeekday();
    return undefined;
  };

  public static toFormat(date: Date, fromatString: string): string {
    return moment(date).format(fromatString);
  }

  /**
   * Filter, which goes through all properties (on level 1) and converts some typescript specific
   * classes to value type/string type fields so they can be sent through API
   * @param data
   * @returns {any} - object with same props as entry parameter but with normalized data
   */
  public static tryConvertAllMomentsToDates(data: any): any {
    try {
      if (!data) {
        return data;
      }

      const prepared = {};

      if (data instanceof Object) {
        var objectKeys = Object.keys(data);
        for (let key in data) {
          if (key in data && objectKeys.indexOf(key) >= 0) {
            if (!data[key]) continue;
            // Convert moment to Date
            if (moment.isMoment(data[key])) {
              prepared[key] = data[key].toDate();
              continue;
            }
            if (Array.isArray(data[key]) && data[key]) {
              prepared[key] = data[key].join(',');
              continue;
            }
            // Other cases
            prepared[key] = data[key];
          }
        }
      }

      return prepared;
    } catch (exception) {
      console.error('Failed to normalize data. Still proceeding...', exception);
      return data;
    }
  }
}
