import {
  ShipmentDraftPricesResponseViewModel,
  ShipmentResponseViewModel,
} from 'App/routes/Tenant/Shipment/ViewModels';
import {
  ShipmentPackagesTotalViewModel,
  ShipmentPackageViewModel,
} from 'App/routes/Tenant/Shipment/ViewModels/ShipmentResponseViewModel';
import { NumberUtilities } from '../NumberUtilities';
import { ShipmentStatus, ShipmentType } from 'store/enums';
import * as React from 'react';
import LocaleUtilities from '../LocaleUtilities';
import StatusProcessingIcon from 'components/Icons/Shipment/StatusProcessingIcon';
import StatusDraftIcon from 'components/Icons/Shipment/StatusDraftIcon';
import StatusReceivedIcon from 'components/Icons/Shipment/StatusReceivedIcon';
import StatusInTransitIcon from 'components/Icons/Shipment/StatusInTransitIcon';
import StatusDeliveredIcon from 'components/Icons/Shipment/StatusDeliveredIcon';
import StatusCanceledIcon from 'components/Icons/Shipment/StatusCanceledIcon';
import { InvoiceDraftRowViewModel } from 'viewModels/invoice/InvoiceDraftRowViewModel';
import { SelectComponentValue } from 'viewModels/common/SelectComponentValue';
import { shipmentFilterStatuses } from 'store/constant';
import Badge from 'reactstrap/lib/Badge';

export default class ShipmentUtilities {
  public static formatFormValuesNumbers(formValues: ShipmentResponseViewModel): any {
    if (!formValues) {
      return;
    }
    formValues.type =
      NumberUtilities.tryFormatFloat(formValues.type.toString()) ?? ShipmentType.Sender;
    if (formValues.consignor?.alternativeAddress) formValues.useAlternativeLoadingAddress = true;
    if (formValues.consignee?.alternativeAddress) formValues.useAlternativeDeliveryAddress = true;
    if (formValues.ldmCorrection) {
      formValues.ldmCorrection =
        NumberUtilities.tryFormatFloat(formValues.ldmCorrection?.toString()) ?? null;
    } else {
      formValues.ldmCorrection = null;
    }
    if (formValues.agreedPrice)
      formValues.agreedPrice = NumberUtilities.tryFormatFloat(formValues.agreedPrice.toString());
    formValues.packages.map((item) => {
      item.totalWeight = NumberUtilities.tryFormatFloat(item.totalWeight?.toString()) ?? null;
      item.volume = NumberUtilities.tryFormatFloat(item.volume?.toString()) ?? null;
      item.value = NumberUtilities.tryFormatFloat(item.value?.toString()) ?? null;
      item.height = NumberUtilities.tryFormatFloat(item.height?.toString()) ?? null;
      item.width = NumberUtilities.tryFormatFloat(item.width?.toString()) ?? null;
      item.length = NumberUtilities.tryFormatFloat(item.length?.toString()) ?? null;
      item.ldm = NumberUtilities.tryFormatFloat(item.ldm?.toString()) ?? null;
      item.quantity = NumberUtilities.tryFormatFloat(item.quantity?.toString()) ?? null;
      item.weight = NumberUtilities.tryFormatFloat(item.weight?.toString()) ?? null;
      return true;
    });
  }

  public static formatInvoiceDraftNumbers(formValues: ShipmentDraftPricesResponseViewModel): any {
    if (!formValues) {
      return;
    }
    const format = (values: InvoiceDraftRowViewModel[]) => {
      if (!values) return false;
      values.map((item) => {
        item.quantity = NumberUtilities.tryFormatInt(item.quantity?.toString()) ?? null;
        item.unitPrice = NumberUtilities.tryFormatFloat(item.unitPrice?.toString()) ?? null;  
        item.price = NumberUtilities.tryFormatFloat(item.price?.toString()) ?? null;
        item.vatRate = NumberUtilities.tryFormatFloat(item.vatRate?.toString()) ?? null;
        item.priceType = NumberUtilities.tryFormatInt(item.priceType?.toString()) ?? null;
        return true;
      });
    };
    format(formValues.sales);
    format(formValues.purchase);
  }

  public static calculateTotals(
    packages: ShipmentPackageViewModel[],
  ): ShipmentPackagesTotalViewModel {
    let result = new ShipmentPackagesTotalViewModel();

    result.quantity = packages.reduce(function (acc, val) {
      const formattedVal = NumberUtilities.tryFormatFloat(val.quantity?.toString()) ?? 0;
      return acc + formattedVal;
    }, 0);
    result.volume = packages.reduce(function (acc, val) {
      const formattedVal = NumberUtilities.tryFormatFloat(val.volume?.toString()) ?? 0;
      return acc + formattedVal;
    }, 0);
    result.ldm = packages.reduce(function (acc, val) {
      const formattedVal = NumberUtilities.tryFormatFloat(val.ldm?.toString()) ?? 0;
      return acc + formattedVal;
    }, 0);
    result.weight = packages.reduce(function (acc, val) {
      const formattedVal = NumberUtilities.tryFormatFloat(val.totalWeight?.toString()) ?? 0;
      return acc + formattedVal;
    }, 0);

    return result;
  }

  public static getStatusColor(status: string) {
    switch (status) {
      case ShipmentStatus[ShipmentStatus.Processing]:
        return 'info';
      case ShipmentStatus[ShipmentStatus.Received]:
        return 'secondary';
      case ShipmentStatus[ShipmentStatus.InTransit]:
        return 'info';
      case ShipmentStatus[ShipmentStatus.Delivered]:
        return 'success';
      case ShipmentStatus[ShipmentStatus.Canceled]:
        return 'danger';
      case ShipmentStatus[ShipmentStatus.Draft]:
        return 'secondary';

      default:
        return 'secondary';
    }
  }

  public static getStatusTranslation(str: string): string {
    return LocaleUtilities.i18n(`shipment-status-${ShipmentStatus[str]}`, 'shipment.status', true);
  }

  public static getStatusIcon(status: string) {
    switch (status) {
      case ShipmentStatus[ShipmentStatus.Processing]:
        return <StatusProcessingIcon width={16} height={16} />;
      case ShipmentStatus[ShipmentStatus.Received]:
        return <StatusReceivedIcon width={18} height={16} />;
      case ShipmentStatus[ShipmentStatus.InTransit]:
        return <StatusInTransitIcon width={20} height={16} />;
      case ShipmentStatus[ShipmentStatus.Delivered]:
        return <StatusDeliveredIcon width={15} height={16} />;
      case ShipmentStatus[ShipmentStatus.Canceled]:
        return <StatusCanceledIcon width={16} height={16} />;
      case ShipmentStatus[ShipmentStatus.Draft]:
        return <StatusDraftIcon width={18} height={16} />;

      default:
        return '';
    }
  }

  public static filterStatuses(): SelectComponentValue[] {
    const result: SelectComponentValue[] = shipmentFilterStatuses.map((item) => {
      return {
        label: ShipmentUtilities.getStatusTranslation(ShipmentStatus[item.toString()]),
        value: item.toString(),
      };
    });
    return result;
  }

  public static statusFormat(status: string): JSX.Element {
    if (!status) {
      return <div />;
    }
    const icon = ShipmentUtilities.getStatusIcon(status);
    return (
      <>
        <Badge className={'shipment-status'} color={ShipmentUtilities.getStatusColor(status)}>
          {LocaleUtilities.i18n(`shipment-status-${status}`, 'shipment.status', true)}
          {icon}
        </Badge>
      </>
    );
  }

  public static amountColumnText(
    quantity?: number,
    ldm?: number,
    totalWeight?: number,
  ): JSX.Element {
    const quantityText = LocaleUtilities.getCurrentLanguage() === 'Estonian' ? 'tk' : 'pcs';
    return (
      <span className="text-center">
        {quantity + ' ' + quantityText} / {ldm ? ldm?.toFixed(2) : '-'} LDM <br />{' '}
        {totalWeight ? totalWeight?.toFixed(1) : '-'} kg
      </span>
    );
  }
}
