import { BACKEND_HOST_MAIN } from 'store/api.constants';
import ApiUtilities from 'helpers/ApiUtils';
import { PaginatedListResult } from 'store/base';
import {
  ShipmentDetailResponseViewModel,
  ShipmentDraftPricesResponseViewModel,
  ShipmentEventViewModel,
  ShipmentResponseViewModel,
  ShipmentsRequestViewModel,
  ShipmentsResponseViewModel,
} from 'App/routes/Tenant/Shipment/ViewModels';
import CommonUtilities from 'helpers/CommonUtilities';
import { ShipmentStatusUpdateRequest } from 'viewModels/shipment/ShipmentStatusUpdateRequest';
import { CmrRequestViewModel } from 'shared/types/CmrRequestViewModel';
import { BasicIdsRequestViewModel } from '../viewModels/common/BasicIdsRequestViewModel';

function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/shipment`;
}

export class ShipmentApi {
  public static getList(
    params: ShipmentsRequestViewModel,
  ): Promise<PaginatedListResult<ShipmentsResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<ShipmentsResponseViewModel>>(getRoot(), {
      ...params,
    });
  }

  public static getSingle(id: string): Promise<ShipmentDetailResponseViewModel | null> {
    return ApiUtilities.handleGet<ShipmentDetailResponseViewModel>(`${getRoot()}/${id}`);
  }

  public static add(formValues: ShipmentResponseViewModel): Promise<string> {
    return ApiUtilities.handlePost<string>(getRoot(), formValues);
  }

  public static update(id: string, formValues: ShipmentResponseViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static getInvoiceDraftInfo(
    id: string,
  ): Promise<ShipmentDraftPricesResponseViewModel | null> {
    return ApiUtilities.handleGet<ShipmentDraftPricesResponseViewModel>(
      `${getRoot()}/${id}/invoice-draft`,
    );
  }

  public static submitInvoiceDraftPrices(
    id: string,
    formValues: ShipmentDraftPricesResponseViewModel,
    type: 'sales' | 'purchase',
  ) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}/invoice-draft/${type}`, formValues);
  }

  public static async downloadLabelsFile(
    shipmentId: string,
    shipmentNumber: string,
    pageSize: 'A4' | 'A5' | 'A6',
    shippingOrderId?: string,
  ) {
    let shippingOrder = '';
    if (shippingOrderId) {
      shippingOrder = shippingOrderId;
    }
    const URL = `${getRoot()}/${shipmentId}/labels`;
    const data = {
      shippingOrderId: shippingOrder,
      paperSize: pageSize,
    };
    const file = await ApiUtilities.handleFileGetPost(URL, data);
    const filename = 'ShippingLabels_' + shipmentNumber + '.pdf';
    CommonUtilities.downloadFile(file, false, filename);
  }

  public static async downloadCmrFile(data: CmrRequestViewModel, cmrNumber: string) {
    const URL = `${getRoot()}/cmr`;
    const file = await ApiUtilities.handleFileGetPost(URL, data);
    const filename = 'CMR_' + cmrNumber + '.pdf';
    CommonUtilities.downloadFile(file, false, filename);
  }

  public static updateStatus(
    id: string,
    formValues: ShipmentStatusUpdateRequest,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/${id}/update-status`, formValues);
  }

  public static events(id: string): Promise<ShipmentEventViewModel[]> {
    return ApiUtilities.handleGet<ShipmentEventViewModel[]>(`${getRoot()}/${id}/events`);
  }

  public static getInboxShipmentsCount(params: any): Promise<number> {
    const URL = `${getRoot()}/inbox-count`;
    return ApiUtilities.handleGet<number>(URL, { ...params });
  }

  public static delete(id: string) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }

  public static importToProject(
    projectId: string,
    formValues: BasicIdsRequestViewModel,
  ): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(
      `${getRoot()}/import-to-project/${projectId}`,
      formValues,
    );
  }

  public static removeFromProject(formValues: BasicIdsRequestViewModel): Promise<boolean> {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/remove-from-project`, formValues);
  }
}
