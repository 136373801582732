import ApiUtilities from 'helpers/ApiUtils';
import { BACKEND_HOST_MAIN } from 'store/api.constants';
import { PaginatedListResult } from 'store/base';
import {
  TenantCreateOrUpdateRequestViewModel,
  TenantResponseViewModel,
  TenantsResponseViewModel,
} from 'App/routes/Admin/Tenants/ViewModels/index';
import TenantsRequestViewModel from 'App/routes/Admin/Tenants/ViewModels/TenantsRequestViewModel';
import { SelectComponentValue } from 'viewModels/common/SelectComponentValue';
import { NullableLocalTenantModel } from 'helpers/AuthUtils';
import { MyTenantResponseViewModel } from 'App/routes/Tenant/Settings/Profile/viewModels';
import TenantInvoiceSettingsViewModel from 'App/routes/Admin/Tenants/ViewModels/TenantInvoiceSettingsViewModel';
import TenantPdfSettingsViewModel from 'App/routes/Admin/Tenants/ViewModels/TenantPdfSettingsViewModel';
import TenantPublicInfoViewModel from '../viewModels/tenant/TenantPublicInfoViewModel';

function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/tenant`;
}

function getTenantRoot() {
  return `${BACKEND_HOST_MAIN()}api/my-tenant`;
}

export class TenantApi {
  public static myTenant(): Promise<NullableLocalTenantModel | null> {
    return ApiUtilities.handleGet<NullableLocalTenantModel>(`${getTenantRoot()}/current`);
  }

  public static publicTenantInfo(tenantCode: string): Promise<TenantPublicInfoViewModel> {
    return ApiUtilities.handleGet<TenantPublicInfoViewModel>(
      `${getTenantRoot()}/public-info/${tenantCode}`,
    );
  }

  public static myTenantAccount(): Promise<MyTenantResponseViewModel | null> {
    return ApiUtilities.handleGet<MyTenantResponseViewModel>(`${getTenantRoot()}/account`);
  }

  public static myTenantAccountUpdate(formValues: MyTenantResponseViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getTenantRoot()}/account`, formValues);
  }

  public static getList(
    params: TenantsRequestViewModel,
  ): Promise<PaginatedListResult<TenantsResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<TenantsResponseViewModel>>(getRoot(), {
      ...params,
    });
  }

  public static getSingle(id: number): Promise<TenantResponseViewModel | null> {
    return ApiUtilities.handleGet<TenantResponseViewModel>(`${getRoot()}/${id}`);
  }

  public static add(formValues: TenantCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePost<number>(getRoot(), formValues);
  }

  public static update(id: number, formValues: TenantCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static remove(id: number) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }

  public static getSelectComponentList(params: {}): Promise<Array<SelectComponentValue>> {
    const URL = `${getRoot()}/select-component-values`;
    return ApiUtilities.handleGet<Array<SelectComponentValue>>(URL, { ...params });
  }

  public static updateInvoiceSettings(formValues: TenantInvoiceSettingsViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getTenantRoot()}/invoice-settings`, formValues);
  }

  public static updatePdfSettings(formValues: TenantPdfSettingsViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getTenantRoot()}/pdf-settings`, formValues);
  }

  public static removeTenantLogo() {
    return ApiUtilities.handlePost<boolean>(`${getTenantRoot()}/delete-logo`, {});
  }
}
